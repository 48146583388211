@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

.footer {
    padding: 0 10px;
    @include center;
    margin-top: 110px;



    .container {
        display: flex;
        justify-content: center;
        justify-content: space-between;
        align-items: end;


        .img {
            position: absolute;
            margin-left: 35%;

            z-index: 99;

        }
    }

    .contacts {
        display: grid;
        align-items: center;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-right: 52%;
        margin-bottom: 30px;
    }
}

@media (max-width: 1780px) {
    .footer {
        .container {
            .img {
                margin-left: 40%;
            }
        }
    }

}

@media (max-width: 1500px) {
    .footer {
        .container {
            .img {
                margin-left: 45%;
            }
        }
    }

}
@media (max-width: 1500px) {
    
}