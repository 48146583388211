@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

.services {
    margin-top: 200px;
    @include center;
    h1 {
        @include h1;
        margin: 0;
        margin-top: 200px;
        margin-bottom: 60px;
    }
    .categories {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }
}

@media (max-width: 1200px) {
    .services {
        h1 {
            text-align: center;
        }
        .categories {
            display: grid;
            grid-template-columns: repeat(2, 2fr);
            grid-template-rows: repeat(6, 1fr);
        }
    }
}