$colorspecial: #00FF8D;

@mixin m($f) {
    margin: $f;
}

@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

@mixin center {
    margin: 0 auto;
    margin-top: 200px;
    max-width: 1200px;
}

.statistic {
    @include center;
    display: flex;
    align-items: center;

    .statistics {

        padding-left: 150px;

        .neonLine {
            @include line;
            margin-bottom: 20px;
        }

        p {
            @include m(0px);
            font-family: 'Comfortaa';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #898989;
            padding-bottom: 15px;
        }

        h1 {
            @include m(0px);
            @include h1;
        }

        .title {
            padding-bottom: 20px;
        }

        .subtitle {
            @include m(0px);
            font-family: 'Comfortaa';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #FFFFFF;
            padding-bottom: 15px;
        }
    }
}

@media (max-width: 1200px) {
    .statistic {

        display: flex;
        justify-content: center;
        margin-top: 200px;

        img {
            width: 350px;
        }

        .statistics {
            padding-left: 40px;
        }
    }
}

@media (max-width: 1000px) {
    .statistic {
        display: grid;
        justify-content: center;
        margin-top: 0px;

        .img {
            display: flex;
            justify-content: center;

            img {
                grid-row-start: 2;
                width: 350px;
            }
        }

        .statistics {
            width: 100%;
            grid-row-start: 1;
            padding-left: 0;

            br {
                display: none;
            }

            p {
                text-align: center;
            }

            .line {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            h1 {
                text-align: center;
            }

            .description {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .subtitle {
                    text-align: center;
                    width: 400px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .statistic {
        .statistics {
            .description {
                .subtitle {
                    width: 300px;
                }
            }
        }
    }
}