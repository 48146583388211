body {
    background-color: #000000;
}

*,
 ::after,
 ::before {
    box-sizing: content-box;
}

.wrapper {
    overflow: hidden;
}