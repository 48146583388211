$line-color: #00FF8D;


@mixin m($f) {
    margin: $f;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    text-align: center;
    font-size: 22x;

    color: #FFFFFF;
}

@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 30px;

}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

.body {

    padding: 0 10px;
    @include center;
    margin-top: 200px;

    .lines {
        height: 3px;
        width: 100%;
        background: linear-gradient(to left, transparent, $line-color, transparent);
        margin: 20px 0;
    }

    @include h1;

    p {
        @include p;


        width: 30vw;
    }

    .wr {
        display: flex;
        width: 1200px;
        overflow: hidden;
        mask-image: linear-gradient(to right,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 100) 20%,
                rgba(0, 0, 0, 100) 80%,
                rgba(0, 0, 0, 0));

        .slider {
            animation: MoveUpDown 25s linear infinite;
            display: flex;

            .sliderItem {

                color: white;

                margin: 20px 70px;
                width: 90px;
                height: auto;
                object-fit: contain;


            }



            @keyframes MoveUpDown {

                from {
                    left: translateX(0);
                }

                to {
                    transform: translateX(-100%);
                }
            }

        }
    }







    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;

        color: #FFFFFF;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .contacts {
        display: grid;
        align-items: center;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        color: white;

        button {
            width: 100px;
            height: 100px;
            background-color: white;
        }

        h1 {
            font-size: 28px;
        }
    }

    .text {


        h1 {
            @include h1;
            margin: 0;
            margin-bottom: 20px;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }
}

@media (max-width:1300px) {
    .body {
        padding: 20px;
    }

}

@media (max-width:1000px) {
    .body {
        .wrapper {
            .wr {
                .slider {
                    .sliderItem {
                        width: 70px;
                        margin: 10px 50px;
                    }
                }
            }
        }



    }

}

@media (max-width:760px) {
    .body {
        padding: 10px;

        .wrapper {
            .container {
                flex-direction: column;

                .text {
                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width:500px) {
    .body {
        .wrapper {
            h1 {
                font-size: 20px;
            }

            .wr {
                .slider {
                    .sliderItem {
                        width: 70px;
                        margin: 5px 30px;
                    }
                }
            }
        }

        padding: 10px;
    }

}