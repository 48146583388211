@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin m($f) {
    margin: $f;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}



@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

.body {
    padding: 0 10px;
    @include center;
    margin: 20vh auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        @include h1;
        margin: 0;
        margin-bottom: 20px;
    }

    .cardWrap {
        background: linear-gradient(rgba(0, 255, 141, 100), rgba(0, 255, 141, 30));
        padding: 1px;
        border-radius: 20px;

        .onfindCard {
            background-color: black;
            border-radius: 20px;
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                object-fit: contain;
                width: 28px;
                margin-right: 18px;
            }

            .divider {
                background: linear-gradient(to right, transparent, #00FF8D, transparent);
                width: 80%;
                height: 1px;
                margin: 15px 0;
            }

            h1 {
                background-image: linear-gradient(to right, #00FF8D, #00FFD1, #00FF8D);
                color: transparent;
                background-clip: text;
                margin: 0;
                font-size: 40px;
                margin-top: 12px;
            }

        }
    }

    .cardGreyWrap {
        background: linear-gradient(to left, white, transparent);
        padding: 1px;
        border-radius: 0 20px 20px 0;

        .onfindCard {
            background-color: black;
            border-radius: 0 20px 20px 0;
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                object-fit: contain;
                width: 28px;
                margin-right: 18px;
            }

            .divider {
                background: linear-gradient(to right, transparent, white, transparent);
                width: 80%;
                height: 1px;
                margin: 15px 0;
            }



        }


    }

    .pointWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;

        p {
            padding: 0;
        }
    }


    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }



    @mixin center {
        margin: 0 auto;
        max-width: 1200px;
    }

    .container {
        @include center;
        display: flex;
        width: 100%;
        align-items: start;
        justify-content: center;

        .needsPhone {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
        }

        .order {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40%;

        }

    }



    .wrapper {
        display: flex;
    }

    .text {
        p {
            text-align: justify;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }
}

@media (max-width:900px) {
    .body {
        text-align: center;

        .container {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .order {
                width: 70%;
            }
        }

        .cardGreyWrap {
            background-color: white;
            border-radius: 20px;

            .onfindCard {
                border-radius: 20px;
            }
        }

    }

}

@media (max-width:650px) {
    .body {
        text-align: center;

        .container {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .order {
                width: 85%;
            }
        }



    }

}

@media (max-width:550px) {
    .body {
        text-align: center;

        h1 {
            font-size: 20px;
        }

        .container {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .order {
                width: 85%;

                .cardWrap {
                    .onfindCard {
                        .pointWrapper {
                            p {
                                text-align: left;
                            }
                        }
                    }
                }
            }

            .cardGreyWrap {


                .onfindCard {
                    .pointWrapper {
                        p {
                            text-align: left;
                        }
                    }
                }
            }
        }



    }

}

@media (max-width:400px) {
    .body {

        .container {


            .order {
                width: 95%;

                p {
                    font-size: 14px;
                }

                .pointWrapper {
                    margin-bottom: 15px;
                }

            }


        }



    }

}