@mixin text {
    @include m(0px);
    font-family: 'Cy Grotesk';
    font-size: 32px;
    color: #fff;
    padding-bottom: 30px;
}

@mixin textp {
    @include m(0px);
    font-family: "Comfortaa";
    font-size: 16px;
    color: #fff;
    padding-bottom: 20px;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin m($f) {
    margin: $f;
}

$colorspecial: #00FF8D;
.advantage {
    @include center;
    display: flex;
    align-items: center;
    width: 100%;
    .advantages {
        margin-top: 100px;
        width: 50%;
        h2 {
            @include text;
            display: flex;
            align-items: center;
            font-size: 28px;
            padding-bottom: 20px;
        }
        img {
            padding-left: 15px;
        }
        p {
            @include textp;
            font-family: 'Comfortaa';
            padding-bottom: 60px;
            font-size: 16px;
            font-weight: 400;
            padding-top: 20px;
            span {
                color: $colorspecial;
            }
        }
        .advantagesText {
            .neonLine {
                height: 2px;
                width: 100px;
                background-color: #74FFC1;
                border-radius: 50px;
                box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
                -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
                -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
            }
        }
    }
    .img {
        width: 500px;
    }
}

@media (max-width: 1500px) {
    .advantage {
        padding: 40px;
        .img {
            width: 60%;
            img {
                width: 600px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .advantage {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .advantages {
            width: 80%;
            h2 {
                font-size: 24px;
            }
        }
        .img {
            display: flex;
            align-items: end;
            overflow: hidden;
            img {
                object-fit: cover;
                width: 330px;
            }
        }
    }
}

@media (max-width: 768px) {
    .advantage {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        .advantages {
            padding: 20px;
            width: 100%;
            h2 {
                font-size: 26px;
            }
            p {
                padding-top: 0;
                font-size: 13px;
            }
        }
        .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 550px) {
    .advantage {
        .advantages {
            h2 {
                font-size: 20px;
            }
            p {
                padding-top: 0;
                font-size: 13px;
            }
        }
    }
}

@media (max-width: 480px) {
    .advantage {
        .advantagesText {
            h2 img {
                display: none;
            }
            p {
                width: 95%;
            }
        }
    }
}