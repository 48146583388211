$colorspecial: #00FF8D;
$font: 'Comfortaa';

@mixin m($f) {
    margin: $f;
}

@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

// HEADER 
.header {
    height: 1000px;
    background-image: url(../../public/img/header/bg.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .main {
        @include center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 200px;

        .pretitle {
            display: flex;
            align-items: center;
        }

        .neonline {
            @include line;
            width: 30px;
            margin-bottom: 20px;
            margin-right: 5px;
        }

        p {
            @include m(0px);
            font-family: $font;
            font-size: 16px;
            color: #fff;
            padding-bottom: 20px;
        }

        h1 {
            @include m(0px);
            font-family: 'Cy Grotesk';
            font-size: 32px;
            color: #fff;
            padding-bottom: 30px;
            text-transform: uppercase;

            span {
                color: $colorspecial;
            }
        }

        h5 {
            @include m(0px);
            font-weight: 400;
            color: #fff;
            font-family: $font;
            font-size: 18px;
            padding-bottom: 30px;

            span {
                color: $colorspecial;
            }
        }

        .phone {
            img {
                margin-right: 70px;
            }
        }

        .btn {
            cursor: pointer;
            font-family: $font;
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }
}



@media (max-width: 1550px) {
    h1 {
        font-size: 28px;
    }

    .header {
        height: 700px;

        .main {
            padding: 40px;
            padding-top: 150px;

            h1 {
                font-size: 28px;
            }

            .phone {
                img {
                    width: 250px;
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 1268px) {
    .header {
        height: 700px;

        .main {
            padding-right: 80px;
            padding: 40px;
            padding-top: 150px;

            .phone {
                img {
                    width: 212px;
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 950px) {
    .header {
        height: 95vh;

        .main {
            flex-direction: column;
            padding-top: 145px;

            .phone {
                width: 100%;
                padding-right: 120px;
                padding-top: 45px;
                display: flex;
                justify-content: end;
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
        height: 600px;

        .main {
            padding-left: 20px;
            padding-top: 130px;

            .pretitle {
                p {
                    font-size: 16px;
                }
            }

            h1 {
                font-size: 20px;
            }

            h5 {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 480px) {
    .header {
        height: 150vh;
        background-image: url(../../public/img/mobile/header/bg.webp);

        .main {
            align-items: center;
            display: flex;
            padding-left: 20px;
            padding-top: 130px;

            .phone {
                padding-right: 0;
                width: fit-content;
            }

            .pretitle {
                p {
                    font-size: 16px;
                }
            }

            h1 {
                font-size: 20px;
            }

            h5 {
                font-size: 16px;
            }
        }
    }
}