@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin m($f) {
    margin: $f;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}



@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}



.body {

    padding: 0 10px;
    @include center;
    margin-top: 85px;
    margin-bottom: 30px;

    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }

    .centrable {
        display: flex;
        flex-direction: column;
        z-index: -11;
        position: absolute;
        align-items: center;
        margin-top: 500px;
        max-width: 1200px;
        width: 100%;

        .green_wrapper {


            width: 25vw;


            .green_line {


                border: 2px dashed #00FF8D;

                &:hover {
                    border: 2px dashed #09ff90;
                }
            }
        }

    }

    @mixin center {
        margin: 0 auto;
        max-width: 1200px;
    }

    .container {


        @include center;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        justify-content: space-between;

        .needsPhone {
            margin-top: 120px;
            width: 30%;
            height: min-content;
            width: min-content;

            .wrap {
                height: min-content;
                display: flex;
                justify-content: center;
                align-items: center;

                .designIcons {
                    width: auto;
                    z-index: 99;
                    position: absolute;
                }

                img {
                    width: 700px;
                    height: auto;
                    object-fit: contain;

                }
            }

        }

        .order {
            display: flex;
            position: relative;
            margin-top: 120px;
        }
    }

    .contacts {
        display: grid;
        align-items: center;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .text {

        margin-left: 100px;

        h1 {
            @include h1;
            margin: 0;
            margin-bottom: 20px;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }
}





@media(max-width:1570px) {
    .body {
        .centrable {
            .green_wrapper {
                margin-left: 5vw;
                width: 30vw;
            }
        }
    }
}

@media(max-width:1430px) {
    .body {
        .centrable {
            .green_wrapper {

                width: 33vw;
            }
        }
    }
}

@media(max-width:1250px) {
    .body {
        .order {
            .text {
                h1 {
                    font-size: 22px;
                }

                p {
                    font-size: 15px;
                }
            }
        }


        .centrable {
            .green_wrapper {

                width: 45vw;
            }
        }
    }
}

@media(max-width:1250px) {
    .body {
        .centrable {
            .green_wrapper {

                width: 40vw;
            }
        }

        .container {
            .needsPhone {
                .wrap {
                    img {
                        width: 600px;
                    }
                }
            }
        }


    }

}

@media(max-width:1040px) {
    .body {
        .container {
            flex-direction: column;
        }
    }
}

@media(max-width:860px) {
    .body {
        .container {
            .needsPhone {
                margin-top: 20px;
            }

            .order {
                margin-top: 20px;

                .text {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }



    }
}