$colorspecial: #00FF8D;

@mixin m($f) {
    margin: $f;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

.needs {
    @include center;
    display: flex;
    margin-top: 200px;
    width: 100%;

    .needsPhone {
        margin-top: 120px;
        width: 40%;

        img {
            width: 500px;
        }
    }

    .order {
        display: flex;
        position: relative;
        margin-top: 120px;
    }

    .text {
        margin-left: 170px;

        h1 {
            @include h1;
            margin: 0;
            margin-bottom: 20px;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }

    .neonLine {
        @include line;
        margin-bottom: 20px;
    }

    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }

    .arrow {
        position: absolute;
        right: 0;
        transform: translate(80%, 30%);
    }
}

@media (max-width: 1200px) {
    .needs {
        .needsPhone {
            img {
                width: 400px;
            }
        }

        .arrow {}
    }

    .order {
        .text {
            margin-left: 40px;
        }
    }
}

@media (max-width: 1000px) {
    .needs {
        .needsPhone {
            img {}
        }

        .arrow {
            display: none;
        }
    }

    .order {
        .text {
            margin-left: 40px;
        }
    }
}

@media (max-width: 768px) {
    .needs {
        display: grid;

        .needsPhone {
            grid-row-start: 2;
            display: flex;
            justify-content: center;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .order {
            grid-row-start: 1;

            .text {
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 480px) {
    .needs {
        margin-top: 0;
    }
}