@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

$colorspecial: #00FF8D;

@mixin m($f) {
    margin: $f;
}

@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

.benefit {
    @include center;
    display: flex;
    justify-content: space-between;
    margin-top: 230px;

    .title {
        h1 {
            @include h1;
        }

        .neonLine {
            @include line;
        }

        p {
            font-family: 'Comfortaa';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #FFFFFF;
        }
    }

    .benefits {
        margin-left: 30px;
        display: grid;
        grid-template-columns: 2fr 2fr;

        .element {
            display: flex;
            align-items: flex-start;
            transition: 0.3s all;

            &:hover {
                transform: translateY(-5%);
            }
        }

        p {
            @include m(0px);
            font-family: 'Comfortaa';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #FFFFFF;
            padding-bottom: 50px;
        }
    }
}

@media (max-width: 1200px) {
    .benefit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            br {
                display: none;
            }

            p {
                width: 60%;
                text-align: center;
            }

            .line {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .benefits {
            display: grid;
            grid-template-columns: repeat(3, 2fr);
            grid-template-rows: repeat(2, 2fr);
            padding-top: 60px;

            .element {
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .benefit {
        .benefits {
            grid-template-columns: repeat(2, 2fr);
            grid-template-rows: repeat(3, 3fr);
        }
    }
}

@media (max-width: 480px) {
    .benefit {
        .benefits {
            .element {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}