@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin m($f) {
    margin: $f;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}



@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

.neonLineHor {
    @include line;
    margin-bottom: 20px;
    margin-right: 5px;
    height: 2px;

}

.body {
    padding: 0 10px;
    @include center;
    margin-top: 85px;
    margin-bottom: 30px;

    h1 {
        @include h1;
        margin: 0;
        margin-bottom: 20px;
    }

    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }

    .centrable {
        display: flex;
        flex-direction: column;
        z-index: -11;
        position: absolute;
        align-items: center;
        margin-top: 500px;

        width: 75%;

        .green_wrapper {


            width: 35vw;

            .green_line {


                border: 2px dashed #00FF8D;
            }
        }

    }

    @mixin center {
        margin: 0 auto;
        max-width: 1200px;
    }

    .container {
        .timeframe {
            background: #00FF8D;
            height: 2px;
            // filter: blur(5px);
            box-shadow: 0 0 5px 2px #00FF8D;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .point {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #00FF8D;
                box-shadow: 0 0 5px 2px #00FF8D;
            }
        }

        .divider {
            border-image: linear-gradient(rgba(40, 40, 40, 0), rgba(40, 40, 40, 100), rgba(40, 40, 40, 0)) 30;
            border-width: 1px;
            border-style: solid;
            width: 0;
            height: 400px;
            margin-left: 50px;

        }

        .linesCapsule {
            margin-left: 20px;
            width: max-content;
            margin-bottom: 10px;

            .lines {
                border: 2.5px solid white;
                margin-bottom: 20px;
                box-shadow: 0 0 5px 5px;

                filter: blur(1px)
            }

            p {
                font-size: 12px;

            }

        }


        @include center;
        display: flex;
        width: 100%;
        align-items: start;
        justify-content: space-between;

        .needsPhone {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
            width: 100%;

        }

        .order {
            width: 45%;
        }

    }

    .contacts {
        display: grid;
        align-items: center;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .wrapper {
        display: flex;
    }

    .weeksWrap {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;

        p {
            font-size: 15px;
        }
    }

    .text {
        p {
            text-align: justify;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }
}

@media (max-width: 1300px) {
    .body {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media (max-width: 850px) {
    .body {
        .container {
            flex-direction: column;

            .needsPhone {
                margin-left: 0;
            }

            .order {
                width: 100%;
            }

        }
    }



}

@media (max-width: 720px) {
    .body {

        .container {

            .weeksWrap {
                p {
                    text-align: center;
                    font-size: 12px;

                }
            }

        }
    }



}

@media (max-width: 400px) {
    .body {

        margin-left: 10px;
        margin-right: 10px;

        h1 {
            font-size: 20px;
        }

        .container {
            .divider {
                width: 30px;
            }
        }

    }



}