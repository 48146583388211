@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

.neonLine {
    @include line;
    margin-bottom: 20px;
    margin-right: 5px;
}

.neonLineHor {
    @include line;
    margin-bottom: 20px;
    margin-right: 5px;
    height: 2px;

}