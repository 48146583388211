@mixin textp {
    @include m(0px);
    font-family: "Comfortaa";
    font-size: 16px;
    color: #fff;
    padding-bottom: 20px;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

$colorspecial: #00FF8D;

@mixin m($f) {
    margin: $f;
}

.download {
    @include center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 200px;
    width: 100%;

    .text {
        .title {
            display: flex;
            align-items: center;

            h1 {
                @include h1;
                margin: 0;
                margin-bottom: 20px;
            }

            img {
                width: 60px;
                height: 60px;
            }
        }

        p {
            @include textp;
            font-family: 'Comfortaa';
            padding-bottom: 15px;

            span {
                color: $colorspecial;
            }
        }
    }

    .imgPhone {
        width: 50%;
    }
}

.btn {
    cursor: pointer;
    font-family: "Comfortaa";
    font-weight: 700;
    font-size: 16px;
    padding: 15px 30px;
    border: none;
    background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
    box-shadow: 0px 0px 15px 2px #CCFF00;
    border-radius: 5px;
}

@media (max-width: 1200px) {
    .download {
        margin-top: 100px;

        .text {
            padding-left: 20px;
        }

        .imgPhone {
            width: 40%;

            img {
                width: 400px;
            }
        }
    }
}

@media (max-width: 768px) {
    .download {
        margin-top: 100px;
        display: flex;
        flex-direction: column;

        .text {
            width: 100%;
            padding-left: 20px;
        }

        .imgPhone {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 480px) {
    .download {
        .text {
            .title {
                img {
                    display: none;
                }
            }
        }
    }
}