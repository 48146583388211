$colorspecial: #00FF8D;

.modal {
    z-index: 999;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
}

.modal.active {
    z-index: 999;
    opacity: 1;
    pointer-events: all;

}

.modal_content {
    padding: 20px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(12.5px);
    border-radius: 20px;
    border: 1px solid $colorspecial;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 230px;
    width: 400px;
    transform: scale(0.5);
    transition: 0.4s all;

    h1 {
        margin: 0;
        width: 100%;
        text-align: center;
        font-family: 'Cy Grotesk';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }

    .graz {
        color: #00FF8D;
        font-family: "Comfortaa";
        font-size: 14px;
        margin-right: 20px;
    }

    .input {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            font-family: "Comfortaa";
            font-size: 18px;
            width: 150px;
            height: 30px;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #00FF8D;
            color: #fff;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 5px 2px #CCFF00;
            border-radius: 5px;
        }
    }

    button {
        outline: 0;
    }

    input {

        outline: 0;
        text-align: center;


    }

    .timing {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        font-family: "Comfortaa";

        .time {
            cursor: pointer;
            background-color: transparent;

            border: none;
            color: #fff;

            &:focus,
            &:hover {
                border-bottom: 1px solid #00FF8D;
            }
        }
    }

    .choice {
        font-family: "Comfortaa";
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;

        input {

            background-color: transparent;
            border: none;
            color: #fff;
            font-size: 24px;
            width: 60px;
        }
    }
}

.modal_wrap {
    display: flex;

    align-items: center;
    color: white;



    p {
        margin: 0;
        margin-right: auto;
        font-size: 15px;


    }

    a {
        margin-left: 10px;
    }
}

.modal_content.active {
    transform: scale(1);
}

@media (max-width: 480px) {
    .graz {
        text-align: center;
    }

    .modal_content.active {
        margin: 0 8px;
        height: auto;
    }

    .input {
        flex-direction: column;
    }

    .btn {
        width: 85%;
        margin-bottom: 15px;
    }
}