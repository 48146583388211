.main_item {
    display: flex;
    justify-content: center;
    a {
        text-decoration: none;
    }
    .item {
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding-top: 20px;
        display: flex;
        box-sizing: border-box;
        border-radius: 20px;
        width: 380px;
        height: 135px;
        transition: 1s all;
        border: 1px solid #00FF8D;
        //border: 1px solid linear-gradient(180deg, #00FF8D 100%, rgba(0, 255, 141, 0) 0%);
        .name {
            margin: 0;
            padding-left: 30px;
            font-family: 'Comfortaa';
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 130%;
            color: #FFFFFF;
        }
        .image {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
    .item:hover {
        transition: 0.2s all;
        transform: translateY(-5%);
    }
}

@media (max-width: 780px) {
    .main_item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        .item {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px;
            padding-top: 20px;
            box-sizing: border-box;
            border-radius: 20px;
            width: 200px;
            height: 180px;
            transition: 1s all;
            border: 1px solid #00FF8D;
            //border: 1px solid linear-gradient(180deg, #00FF8D 100%, rgba(0, 255, 141, 0) 0%);
            .name {
                text-align: center;
                margin: 0;
                padding: 0;
                font-family: 'Comfortaa';
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                line-height: 130%;
                color: #FFFFFF;
            }
            .name {
                width: 100%;
                text-align: center;
                font-size: 16px;
            }
            .image {
                width: 100%;
                text-align: center;
                padding: 0;
            }
        }
        .item:hover {
            transition: 0.2s all;
            transform: translateY(-5%);
        }
    }
}

@media (max-width: 480px) {
    .main_item {
        display: flex;
        justify-content: center;
        a {
            text-decoration: none;
        }
        .item {
            width: 150px;
            height: 200px;
            .name {
                text-align: center;
                margin: 0;
                padding: 0;
                font-family: 'Comfortaa';
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                line-height: 130%;
                color: #FFFFFF;
            }
            .name {
                width: 100%;
                text-align: center;
                font-size: 16px;
            }
            .image {
                text-align: center;
                padding: 0;
            }
        }
        .item:hover {
            transition: 0.2s all;
            transform: translateY(-5%);
        }
    }
}