@mixin center {
    margin: 30vh auto;
    max-width: 1200px;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

@mixin m($f) {
    margin: $f;
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

.body {
    padding: 0 10px;
    @include center;

    display: flex;
    flex-direction: column;
    justify-content: center;


    .wrapper {
        display: flex;
        align-items: center
    }

    .num {
        @include h1;
        color: #00FF8D;
        font-size: 96px;
        margin: 0;
    }

    .pos {
        position: relative;
        height: fit-content;
        z-index: 99;
        height: 110px;
        width: 400px;
        transition: 1s all;

        &:hover {
            transition: 0.2s all;
            transform: translateY(-5%);
        }

        .border {
            position: absolute;
            left: -20px;
            background: linear-gradient(to right, #00FF8D, transparent);
            padding: 1px;
            border-radius: 20px;

            .card {
                min-width: 300px;
                width: max-content;
                border-radius: 20px;
                padding: 15px;
                display: flex;
                align-items: center;
                padding: 12px;
                background-color: black;

                img {
                    object-fit: contain
                }

                p {
                    padding: 0;
                    margin-left: 8px
                }
            }
        }

    }


    h1 {
        @include h1;
        margin: 0;
        margin-bottom: 20px;
    }

    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }


}

@media (max-width:1700px) {
    .body {


        .pos {
            width: 350px;


        }
    }

}

@media (max-width:1350px) {
    .body {
        padding: 5px;

        .num {
            font-size: 80px;
        }

        .pos {


            .border {
                .card {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

@media (max-width:1260px) {
    .body {
        margin: 20vh 40px;

    }
}

@media (max-width:860px) {
    .body {
        margin: 10vh auto;
        align-items: start;

        h1 {
            text-align: center;
            font-size: 22px;
        }

        .wrapper {
            font-size: 16px;
        }
    }
}

@media (max-width:400px) {
    .body {


        h1 {

            font-size: 20px;
        }

        .wrapper {
            margin-bottom: 20px;

            align-items: center;


            .num {
                padding-bottom: 0;
            }

            .pos {

                height: fit-content;

                .border {
                    position: relative;
                    width: fit-content;

                    .card {
                        min-width: 50px;


                        p {
                            font-size: 14px;
                            max-width: 180px;
                        }
                    }
                }
            }
        }

    }
}