$colorspecial: #00FF8D;

@mixin center {
    margin: 0 auto;
    max-width: 1300px;
}

@mixin textp {
    margin: 0;
    font-family: "Comfortaa";
    font-size: 16px;
    color: #fff;
    padding-bottom: 20px;
}

.modal {
    z-index: 999;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
}

h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

.modal.active {
    z-index: 999;
    opacity: 1;
    pointer-events: all;

}

.body {
    img {
        margin-left: 50px
    }

    @include center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 20px;
    }

    .container {
        p {
            @include textp;
        }

        .time_wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: start;

            .choice {
                font-family: "Comfortaa";

                display: flex;
                justify-content: flex-end;

                input {
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    font-size: 24px;
                    width: 60px;
                }
            }
        }

        display: flex;

        .modal_content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 40px;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.75);
            backdrop-filter: blur(12.5px);
            border-radius: 20px;
            border: 1px solid $colorspecial;
            height: fit-content;
            width: 20vw;

            transition: 0.4s all;

            h1 {
                margin: 0;
                width: 100%;
                text-align: center;
                font-family: 'Cy Grotesk';
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 130%;
                display: flex;
                align-items: center;
                color: #FFFFFF;
            }

            .graz {
                color: #00FF8D;
                font-family: "Comfortaa";
                font-size: 14px;
                margin-right: 20px;
            }

            .input {

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin: 15px 0;

                input {
                    font-family: "Comfortaa";
                    font-size: 18px;
                    width: 150px;
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid #ffffff49;
                    color: #fff;
                }

                .btn {
                    cursor: pointer;
                    font-family: "Comfortaa";
                    font-weight: 700;
                    font-size: 16px;
                    padding: 15px 30px;
                    border: none;
                    background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
                    box-shadow: 0px 0px 5px 2px #CCFF00;
                    border-radius: 5px;
                }
            }

            button {
                outline: 0;
            }

            input {
                outline: 0;
                text-align: center;
            }

            .timing {
                margin-top: 20px;

                display: flex;
                flex-direction: column;
                align-items: start;
                font-family: "Comfortaa";

                .time {

                    cursor: pointer;
                    background-color: transparent;

                    border: none;
                    color: #fff;

                    &:focus,
                    &:hover {
                        border-bottom: 1px solid #00FF8D;
                    }

                    padding:10px;
                }

                button {
                    @include textp;
                }
            }


        }
    }

}




@media (max-width: 480px) {
    .graz {
        text-align: center;
    }

    .modal_content.active {
        margin: 0 8px;
        height: auto;
    }

    .input {
        flex-direction: column;
    }

    .btn {
        width: 85%;
    }
}

@media (max-width: 850px) {

    .body {
        h1 {
            text-align: center;
        }

        .container {
            flex-direction: column;
            align-items: center;

            width: 80vw;

            .modal_content {
                width: 90%;
            }
        }
    }
}

@media (max-width: 550px) {
    .body {

        .container {



            .modal_content {
                .input {
                    width: 100%;


                    .btn {
                        width: 80%;
                    }
                }
            }
        }
    }

}