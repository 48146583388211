@mixin center {
    margin: 0 auto;
    max-width: 1200px;

}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

@mixin m($f) {
    margin: $f;
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

.body {
    padding: 0 20px;
    @include center;
    margin: 20vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container {
        @include center;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .divider {
            border-image: linear-gradient(rgba(40, 40, 40, 0), rgba(40, 40, 40, 100), rgba(40, 40, 40, 0)) 30;
            border-width: 1px;
            border-style: solid;
            width: 0;
            height: 100px;
            margin-left: 50px;
        }

        .needsPhone {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
        }

        .order {
            width: 50%;
        }

    }

    .text {

        margin-left: 170px;

        h1 {
            @include h1;
            margin: 0;
            margin-bottom: 20px;
        }

        .stats {
            background-image: linear-gradient(to right, #00FF8D, #00FFD1, #00FF8D);
            color: transparent;
            background-clip: text;
        }

        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }

    h1 {
        @include h1;
        margin: 0;
        margin-bottom: 20px;
    }

    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }
}

@media (max-width:1200px) {
    .body {
        .text {
            margin-left: 20px;
        }
    }
}

@media (max-width:800px) {
    .body {
        p {
            font-size: 14px;
        }



        .container {
            h1 {
                font-size: 22px;

            }

            .order {
                img {
                    width: 320px;
                }
            }
        }
    }
}

@media (max-width:550px) {
    .body {

        margin: 10vh auto;

        p {
            font-size: 14px;
        }



        .container {
            h1 {
                font-size: 22px;

            }

            .order {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .text {
                    margin-left: 0;
                }
            }

            .needsPhone {
                margin-top: 20px;
                margin-left: 0;
            }

            flex-direction: column;
            align-items: center;

            .order {
                img {
                    width: 320px;
                }
            }
        }
    }
}