@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

@mixin m($f) {
    margin: $f;
}

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}



@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin p {
    @include m(0px);
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

@mixin center {
    margin: 100px auto;
    max-width: 1200px;

}

.body {
    padding: 0 10px;
    @include center;
    margin: 22vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        @include h1;
        margin: 0;
        margin-bottom: 20px;
    }

    p {
        @include p;
        padding: 0;
        padding-bottom: 15px;
    }

    .centrable {
        display: flex;
        flex-direction: column;
        z-index: -11;
        position: absolute;
        align-items: center;
        margin-top: 500px;

        width: 75%;

        .green_wrapper {


            width: 35vw;

            .green_line {


                border: 2px dashed #00FF8D;
            }
        }

    }

    @mixin center {
        margin: 0 auto;
        max-width: 1200px;
    }

    .divider {

        margin: 25px 0;
        width: 100%;
        height: 3px;

    }

    .vertDivider {

        margin: 0 40px;
        width: 1px;
        height: 50px;
    }

    .linesCapsule {
        margin-left: 20px;
        width: max-content;

        .lines {
            border: 2.5px solid white;
            margin-bottom: 20px;
            box-shadow: 0 0 5px 5px;

            filter: blur(1px)
        }

    }



    .needsPhone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        h1 {
            margin: 0;
        }

    }

    .order {
        width: 60%;
    }


    .contacts {
        display: grid;
        align-items: center;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .wrapper {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0 40px;

        p {
            padding: 0;
            width: 45%;
        }

        .label {
            font-size: 48px;
            margin-left: auto;
            margin-right: 50px;
        }
    }

    .text {
        .btn {
            cursor: pointer;
            font-family: "Comfortaa";
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            border: none;
            background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
            box-shadow: 0px 0px 15px 2px #CCFF00;
            border-radius: 5px;
        }
    }
}

@media (max-width:1000px) {
    .body {
        align-items: center;

        .order {
            width: 90%;

            .text {

                text-align: center;
            }
        }


        .needsPhone {
            width: 70%;

            .wrapper {
                h1 {
                    text-align: center;
                    margin-bottom: 20px;
                }

                flex-direction: column;
                align-items: center;
                justify-content: center;

                .label {
                    margin: 0;
                }

                p {
                    width: 80%;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media (max-width:750px) {
    .body {
        h1 {

            font-size: 20px;
        }

        p {
            font-size: 14px;
        }
    }
}

@media (max-width:600px) {
    .body {
        h1 {

            text-align: center;
        }
    }
}

@media (max-width:425px) {
    .wrapper {
        p {
            text-align: center;
        }
    }
}