$colorspecial: #00FF8D;

@mixin m($f) {
    margin: $f;
}

@mixin line {
    height: 2px;
    width: 100px;
    background-color: #74FFC1;
    border-radius: 50px;
    box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -webkit-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
    -moz-box-shadow: -1px 1px 13px 3px rgba(0, 255, 141, 1);
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    height: 90px;
    width: 100%;
    position: fixed;
    z-index: 999;

    .items {
        display: flex;
        padding-left: 300px;
        padding-right: 300px;

        li {
            list-style-type: none;

            a {
                font-display: swap;
                color: #fff;
                text-decoration: none;
                margin: 0 25px;
                font-size: 14px;
                position: relative;
                /*задаём цвет ссылки*/
                cursor: pointer;
                line-height: 1;
                /*задаём высоту строки*/
            }

            a:after {
                font-display: swap;
                display: block;
                position: absolute;
                left: 0;
                /*изменить на right:0;, чтобы изменить направление подчёркивания */
                width: 0;
                /*задаём длинну линии до наведения курсора*/
                height: 2px;
                /*задаём ширину линии*/
                background-color: #74FFC1;
                /*задаём цвет линии*/
                content: "";
                transition: width 0.3s ease-out;
                /*задаём время анимации*/
            }

            a:hover:after,
            a:focus:after {
                font-display: swap;
                width: 100%;
                /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
            }
        }
    }

    .social {
        .social_item {
            padding: 0 10px;
        }
    }
}

@media (max-width: 1300px) {
    .nav {
        display: flex;
        justify-content: space-between;
        height: 100px;

        img {
            padding-left: 40px;
        }

        .items {
            padding-left: 0px;
            padding-right: 0px;

            .item {
                margin: 20px;
            }
        }

        .social {
            padding-right: 40px;

            .social_item {
                padding: 0;
            }
        }
    }
}

@media (max-width: 800px) {
    .nav {
        .items {
            margin-left: 30px;

            .item {
                margin: 10px;
            }
        }
    }
}

@media (max-width: 650px) {
    .nav {
        .items {
            display: none;
        }
    }
}

@media (max-width: 400px) {
    .nav {
        .social {
            padding-right: 10px;
        }

        img {
            padding-left: 20px;
        }
    }


}