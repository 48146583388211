$font: 'Comfortaa';

@mixin h1 {
    font-family: 'Cy Grotesk';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}

@mixin center {
    margin: 0 auto;
    max-width: 1200px;
}

:root {
    --swiper-theme-color: #585858;

    .swiper-slide {
        margin-left: 40px;
        width: 200px;
    }
}

$colorspecial: #00FF8D;

.btn {
    cursor: pointer;
    font-family: $font;
    font-weight: 700;
    font-size: 16px;
    padding: 15px 30px;
    border: none;
    background: linear-gradient(92.05deg, #CCFF00 0%, #CEFFA8 47.4%, #CCFF00 100%);
    box-shadow: 0px 0px 15px 2px #CCFF00;
    border-radius: 5px;
    width: fit-content;
    margin-left: auto;
    margin-right: 20px;
}

.footer_wrap {
    display: flex;
    flex-direction: column;
}

.wrapper {
    @include center;

    .title {
        @include center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 200px;

        h1 {
            text-align: center;
            @include h1;
        }

        .content {
            .line {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .calc {
        font-family: $font;

        .stages_row {
            display: flex;

            .stage {
                cursor: pointer;
                text-align: left;
                margin: 10px;
                margin-left: 0px;
                margin-right: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $colorspecial;
                width: 220px;
                height: 80px;
                color: #fff;
                border-radius: 20px;
                background-color: transparent;

                svg {
                    width: 50px;
                    fill: #fff;
                }

                p {
                    font-family: $font;
                    font-weight: 900;
                    font-size: 14px;
                    line-height: 130%;
                    padding: 10px;
                    color: #fff;
                }

                .numb {
                    padding: 20px;
                    font-family: $font;
                    font-style: normal;
                    font-weight: 900;
                }

                &:hover {
                    background: linear-gradient(90deg, #00FF8D 0%, #82FFC7 48.96%, #00FF8D 100%), rgba(0, 0, 0, 0.25);

                    p {
                        color: #000;
                    }

                    svg {
                        fill: black;
                    }
                }
            }
        }

        .manual {
            display: flex;
            justify-content: center;
            margin: 30px;

            p {
                font-family: $font;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                color: #ffffffdc;
            }
        }

        .points_row {
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(3, 250px);
            grid-template-rows: repeat(2, 1fr);

            .point {
                cursor: pointer;
                margin: 10px;
                //margin-left: 100px;
                text-align: center;
                border: 1px solid $colorspecial;
                width: 220px;
                height: 80px;
                color: #fff;
                border-radius: 20px;
                background-color: transparent;

                p {
                    font-family: $font;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 14px;
                    line-height: 130%;
                    color: #FFFFFF;
                }

                &:hover {
                    background: linear-gradient(90deg, #00FF8D 0%, #82FFC7 48.96%, #00FF8D 100%), rgba(0, 0, 0, 0.25);

                    p {
                        color: #000;
                    }
                }
            }
        }


        .activeBtn {
            cursor: pointer;
            text-align: center;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $colorspecial;
            width: 220px;
            height: 80px;
            color: #fff;
            border-radius: 20px;
            background-color: transparent;
            background: linear-gradient(90deg, #00FF8D 0%, #82FFC7 48.96%, #00FF8D 100%), rgba(0, 0, 0, 0.25);

            p {
                font-family: $font;
                font-style: normal;
                font-weight: 900;
                font-size: 14px;
                line-height: 130%;
                padding: 10px;
                color: #000000;
            }

            svg {
                text-align: center;
                fill: black;
                width: 50px;
            }

            .numb {
                padding: 20px;
                font-family: $font;
                font-style: normal;
                font-weight: 900;
            }
        }

        .calc_footer {
            display: flex;
            justify-content: space-between;
            margin: 10px;


            .steps {
                display: flex;
                align-items: center;

                .step {
                    cursor: pointer;
                    background: linear-gradient(90deg, #00FF8D 0%, #82FFC7 48.96%, #00FF8D 100%);
                    box-shadow: 0px 0px 10px #00FF8D;
                    border: none;
                    border-radius: 5px;
                    width: 120px;
                    text-align: center;
                    margin-right: 20px;
                    transition: 0.5s all;

                    p {
                        font-family: $font;
                        font-size: 16px;
                        line-height: 130%;
                        color: #000000;
                    }
                }

                .count {
                    font-family: $font;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130%;
                    color: #FFFFFF;
                }
            }

            .price {
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                    padding-right: 20px;
                    font-family: $font;
                    font-size: 16px;
                    line-height: 130%;
                    text-align: right;
                    color: #FFFFFF;
                    width: 290px;
                }

                .price_num {
                    width: fit-content;
                    margin: 0;
                    font-family: 'Cy Grotesk';
                    font-size: 25px;
                    line-height: 130%;
                    text-align: right;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .wrapper {
        display: none;
    }
}

@media (max-width: 768px) {
    .wrapper {
        display: none;
    }
}